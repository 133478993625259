import { For, onMount, onCleanup } from "solid-js";
import { redirect } from "@solidjs/router";
import dayjs from "dayjs";
import Message from "/src/message.jsx";

export default function ThreadList ({ messages }) {
   const mins5 =  1 * 60 * 1000;
   let activityTimeout = null;
   const handleScroll = (event) => {
     if (activityTimeout) {
       clearTimeout(activityTimeout); 
     }
     activityTimeout = setTimeout(function(){
       sessionStorage.removeItem('token');
       window.location = '/';
     }, mins5);
  };

  onMount(() => {
    document.addEventListener('scroll', handleScroll);
    document.addEventListener('click', handleScroll);
  });

  onCleanup(() => {
    document.removeEventListener('scroll', handleScroll);
    document.addEventListener('click', handleScroll);
  });

  const msInHour = 3600 * 1000;
  let lastDate = null;
  let lastDay = null;

  const renderMessage = (message) => {
    const currentDate = dayjs.unix(message.timestamp);
    let showDate = false;
    let printDay = false;
    if (!lastDate) {
      lastDate = dayjs.unix(message.timestamp);
      lastDay = dayjs.unix(message.timestamp);
      showDate = true;
      printDay = true;
    }
    if (currentDate.diff(lastDate) > msInHour) {
      lastDate = currentDate;
      showDate = true;
    }
    if (lastDay.diff(currentDate, 'day')) {
      lastDay = currentDate;
      printDay = true;
    }
    return(
      <Message message={message} date={currentDate} printDay={printDay} showDate={showDate} /> 
    )
  }

  return (
    <>
      <div class="thread-list">
        <For each={messages}>{renderMessage}</For>
      </div>
    </>
  );
}
